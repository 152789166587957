import styles from "./styles.module.scss";

interface ProgressBarProps {
  currentIndex: number;
  totalItems: number;
  bgContainer?: string;
  bgInner?: string;
}
const ProgressBar = ({
  currentIndex,
  totalItems,
  bgContainer = "#f48fb1",
  bgInner = "#d11660"
}: ProgressBarProps) => {
  const progress = ((currentIndex + 1) / totalItems) * 100;

  return (
    <div className={styles.progressWrap} style={{ backgroundColor: bgContainer }}>
      <div
        style={{
          width: `${progress}%`,
          backgroundColor: bgInner
        }}
        className={styles.progressWrap__inner}
      />
    </div>
  );
};

export { ProgressBar };
