import styles from "./styles.module.scss";
import { useEarlyAccessContext } from "context";

const LearnMore = () => {
  const { activeForm } = useEarlyAccessContext();
  return (
    <div className={styles.bg}>
      <div>
        <h4>
          {activeForm === "personal" || activeForm === "company"
            ? "Personal and Company Info"
            : "Feature Requests"}
        </h4>
        <p>
          {activeForm === "personal" || activeForm === "company"
            ? `Understanding your demographic information  and knowing more about your company also allows us to offer targeted support and guidance tailored to your industry or business needs.`
            : `By sharing your problems, you contribute to making our product more useful, efficient, and enjoyable for your team. Your feedback allows us to make sure we are solving your exact problems and building what you’d love to use.`}
        </p>
      </div>
    </div>
  );
};

export { LearnMore };
