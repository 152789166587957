import { Spinner } from "components";
import { BlogUI } from "modules";
import { BlogType } from "modules/blog/heroSection";
import { useState, useEffect } from "react";

const BASE_URL = process.env.REACT_APP_NOTION_URL;

const Blog = () => {
  const [overviewData, setOverViewData] = useState<BlogType[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [next_cursor, setNextCursor] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);


  const fetchData = async () => {
    if (!BASE_URL) {
      console.error("REACT_APP_NOTION_URL is not defined");
      return;
    }

    try {
      setLoading(true);
      setOverViewData([]);
      setNextCursor(undefined); //reset on page change

      const url = next_cursor ? `${BASE_URL}?next_cursor=${next_cursor}` : BASE_URL;
      const response = await fetch(url);
      const payload = await response.json();

      setOverViewData(payload.list);
      setHasMore(payload.has_more);

      if (payload.has_more) {
        setNextCursor(payload.next_cursor);
      } else {
        setNextCursor(undefined);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePagination = (direction: "next" | "prev") => {
    if (direction === "next") {
      if (next_cursor !== undefined) {
        setCurrentPage((prev) => prev + 1);
        fetchData();
      }
    } else if (direction === "prev") {
      if (currentPage > 1) {
        setCurrentPage((prev) => prev - 1);
        fetchData();
      }
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <BlogUI
          overviewData={overviewData}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          currentPage={currentPage}
          handlePagination={handlePagination}
          hasNext={hasMore}
        />
      )}
    </>
  );
};

export { Blog };
