import { Title, Section } from "components";
import styles from "./styles.module.scss";
import { ProductsSlider } from "./productsSlider";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const ProductSuites = () => {
  const zoomIn = useRef(null);

  useGSAP(
    () => {
      gsap.from(zoomIn.current, {
        duration: 1,
        scaleX: 0.5,
        scaleY: 0.5,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: zoomIn.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: zoomIn }
  );

  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.content}>
      <div ref={zoomIn} className={styles.content__top}>
        <Title variant="small">Product suite</Title>
        <h2 className={styles.content__heading}>
          A fully integrated suite of products that power your travel business.
        </h2>

        <div className={styles.content__paragraphWrap}>
          <p className={styles.content__paragraph}>
            We bring together everything that’s required to acquire and nurture leads, create
            iteneries, issue invoices and accept payments, manage customers and teams across boards.
          </p>
          <p className={styles.content__paragraph}>
            We also help travel agencies stay in-touch with travel and visa regulations.{" "}
          </p>
        </div>
      </div>

      <ProductsSlider />
    </Section>
  );
};

export { ProductSuites };
