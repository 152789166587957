import { BlockType } from "notion-block-renderer";
import { Subscribe } from "../subscribe";
import { BlogDetails } from "./blogDetails";

interface Props {
  blocks: BlockType[];
  handleBack: () => void;
}

const SingleBlogUI: React.FC<Props> = (props) => {
  return (
    <>
      <BlogDetails {...props} />
      {/* <Subscribe /> */}
    </>
  );
};

export { SingleBlogUI };
