import { CompletedUI } from "modules";

const Completed = () => {
  return (
    <>
      <CompletedUI />
    </>
  );
};

export { Completed };
