import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import styles from "./styles.module.scss";

interface InputProps {
  name?: string;
  parentClassName?: string;
  error?: string;
  label?: string;
  onChange: (value: string) => void;
  value?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const CustomPhoneInput: React.FC<InputProps> = ({
  parentClassName,
  error,
  label,
  onChange,
  value,
  name
}) => {
  return (
    <>
      <div className={`${styles.inputWrapper} ${parentClassName} ${error ? styles.error : ""}`}>
        {label && <label className={styles.label}>{label}</label>}

        <PhoneInput
          country="ng"
          placeholder="Your phone number"
          inputClass={styles.input}
          inputProps={{
            name,
            required: true
            // autoFocus: true,
          }}
          onChange={(value, country: any, e, formattedValue) => {
            onChange(value);
          }}
          value={value}
        />

        {error && <small className={styles.message}>{error}</small>}
      </div>
    </>
  );
};
export { CustomPhoneInput };
