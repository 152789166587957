import { DropdownItem } from "components";
import styles from "./styles.module.scss";
import { MenuPortalData } from "layout/landing/navbar";

const MenuPortal = ({ products, headers }) => {
  return (
    <section className={styles["portalWrap"]}>
      <section className={styles["portalWrap__header"]}>
        {headers.map((title: string) => (
          <span key={title}>{title}</span>
        ))}
      </section>

      <section className={styles["portalWrap__products"]}>
        {products.map((product: MenuPortalData) => (
          <DropdownItem key={product.title} {...product} />
        ))}
      </section>
    </section>
  );
};

export { MenuPortal };
