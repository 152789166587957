import { TourAndMigrationUI } from "modules";
import React from "react";

const TourAndMigration = () => {
  return (
    <>
      <TourAndMigrationUI />
    </>
  );
};

export { TourAndMigration };
