import { MedicalTravelsUI } from "modules";

const MedicalTravel = () => {
  return (
    <>
      <MedicalTravelsUI />
    </>
  );
};

export { MedicalTravel };
