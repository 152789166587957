import { NavContextProvider } from "context";
import { Footer } from "./footer";
import { GetStarted } from "./getStarted";
import { Navbar, NavbarProps } from "./navbar";

interface LandingLayoutProps extends NavbarProps {
  children: React.ReactNode;
  hideGetStarted?: boolean;
}

const LandingLayout: React.FC<LandingLayoutProps> = ({ children, active, hideGetStarted }) => {
  return (
    <>
      <NavContextProvider>
        <Navbar active={active} />
        {children}
        {hideGetStarted ? "" : <GetStarted />}
        <Footer />
      </NavContextProvider>
    </>
  );
};

export { LandingLayout };
