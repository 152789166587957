import styles from "./styles.module.scss";
import Select, { SingleValue } from "react-select";
export type OptionType = { value: string; label: string };

interface SelectInputProps {
  options: OptionType[];
  selectedOption: OptionType | null;
  handleSelectChange: (newValue: OptionType | null) => void;
  placeholder?: string;
  label?: string;
  name: string;
  error?: string;
}
const SelectInput: React.FC<SelectInputProps> = ({
  options,
  selectedOption,
  handleSelectChange,
  placeholder,
  label,
  name,
  error
}) => {
  return (
    <div>
      {label && <label className={styles.label}>{label}</label>}{" "}
      <Select
        defaultValue={selectedOption}
        onChange={handleSelectChange}
        options={options}
        value={selectedOption}
        placeholder={placeholder}
        name={name}
        className={styles.select}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: "1px solid #d0d5dd",
            boxShadow: "0px 1px 2px 0px #1018280d",
            padding: "3px",
            height: "44px",
            borderRadius: 6,
            fontSize: "14px",
            color: "var(--neutral-40)"
          }),
          placeholder:(baseStyles, state) => ({
            ...baseStyles,
            color: "#d0d5dd"
          })
        }}
      />
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export { SelectInput };
