import { CaseStudies } from "./caseStudies";
import { HeroSection } from "./heroSection";
import { ManageAgency } from "./manageAgency";
import { Remarket } from "./remarket";
import { Segmentation } from "./segmentation";
import { SellInternationally } from "./sellInternationally";
import TourLocation from "./tourLocation";

const TourAndMigrationUI = () => {
  return (
    <>
      <HeroSection />
      <SellInternationally />
      <ManageAgency />
      {/* <TourLocation /> */}
      <Segmentation />
      <Remarket />
      <CaseStudies />
    </>
  );
};

export { TourAndMigrationUI };
