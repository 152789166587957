import { Title, Section } from "components";
import styles from "./styles.module.scss";
import {
  OfferingsEdu,
  pipelineImg,
  SendupEdu,
  SendupMobile,
  booksImg,
  salesImg,
  sendupImg
} from "assets";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const UnitedPlatform = () => {
  const slideUp1 = useRef(null);
  const slideUp2 = useRef(null);
  const slideUp3 = useRef(null);
  const slideUp4 = useRef(null);
  const slideLeft = useRef(null);

  useGSAP(
    () => {
      gsap.from(slideUp1.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp1.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp1 }
  );

  useGSAP(
    () => {
      gsap.from(slideUp2.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp2.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp2 }
  );

  useGSAP(
    () => {
      gsap.from(slideUp3.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp3.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp3 }
  );

  useGSAP(
    () => {
      gsap.from(slideUp4.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp4.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp4 }
  );

  useGSAP(
    () => {
      gsap.from(slideLeft.current, {
        duration: 1,
        opacity: 0,
        x: -90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideLeft.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideLeft }
  );

  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.content}>
      <div ref={slideLeft} className={styles.contentTop}>
        <Title variant="small">Unified platform</Title>
        <h2 className={styles.contentTop__bigHeader}>Powerhouse for all your business needs.</h2>
        <p className={styles.contentTop__paragraph}>
          With our all-in-one software, we have eliminated the time, effort, and costs associated
          with running multiple business management softwares so you can get the most productivity.{" "}
        </p>
      </div>

      <div className={styles.contentBtm}>
        <div ref={slideUp1} className={styles.contentBtm__item}>
          <img src={pipelineImg} alt="" className={styles.image} />
          <div>
            <Title variant="small" style={{ color: "#D11660" }} className={styles.title}>
              Pipeline
            </Title>
            <Title variant="small" className={styles.heading}>
              Know your students
            </Title>
            <p>
              Increase your lead conversion and guide students through your sales funnel with
              built-in visual representations of the different stages of the sales process as a
              guide.
            </p>
          </div>
        </div>
        <div ref={slideUp2} className={styles.contentBtm__item}>
          <img src={booksImg} alt="finance" className={styles.image} />
          <div>
            <Title variant="small" style={{ color: "#35B93F" }} className={styles.title}>
              Books
            </Title>
            <Title variant="small" className={styles.heading}>
              Automate the maths
            </Title>
            <p>
              Vobb simplifies financing for you by accurately estimating commission per students,
              generating invoices, and reconciling revenues over a period of time, so you don’t have
              to.
            </p>
          </div>
        </div>
        <div ref={slideUp3} className={styles.contentBtm__item}>
          <img src={salesImg} alt="pipeline" className={styles.image} />
          <div>
            <Title variant="small" style={{ color: "#00C3A2" }} className={styles.title}>
              Sales
            </Title>
            <Title variant="small" className={styles.heading}>
              Amaze your students.
            </Title>
            <p>
              Get your students's attention with Vobb's unlimited pool of institutions,
              better-suited offerings, and fast and responsive application to impress your students
              and give the best possible experience.
            </p>
          </div>
        </div>
        <div ref={slideUp4} className={styles.contentBtm__item}>
          {/* <SendupEdu className={`${styles.image} ${styles.adjust}`} /> */}
          <img src={sendupImg} alt="pipeline" className={styles.image} />

          <div>
            <Title variant="small" style={{ color: "#F4511E" }} className={styles.title}>
              Sendup
            </Title>
            <Title variant="small" className={styles.heading}>
              Stay ahead of the noise.
            </Title>
            <p>
              Grow your email list, streamline email flows, market strategically, and stay in touch
              with customers and institutions by automating, scheduling and sending targeted email
              campaigns without leaving the platform.
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default UnitedPlatform;
