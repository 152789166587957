import { Section, Title } from "components";
import styles from "./styles.module.scss";
import { Coventry, Liverpool, Minnesota, Murdoch, Stanford, Washington, Wellington } from "assets";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const ScaleGlobally = () => {
  const slideRight = useRef(null);
  const zoomIn = useRef(null);

  useGSAP(
    () => {
      gsap.from(slideRight.current, {
        duration: 1,
        opacity: 0,
        x: -90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideRight.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideRight }
  );

  useGSAP(
    () => {
      gsap.from(zoomIn.current, {
        duration: 0.8,
        // opacity: 0,
        // x: 90,
        scaleX: 0.6,
        scaleY: 0.6,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: zoomIn.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: zoomIn }
  );

  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.contentbg}>
      <div className={styles.contentTop}>
        <div ref={slideRight} className={styles.contentTop__left}>
          <Title variant="small">Scale globally with Atlas</Title>
          <h3 className={styles.contentTop__bigHeader}>
            Gain unrestricted access to 450+ universities across the globe
          </h3>
          <p className={styles.contentTop__paragraph}>
            Our team of experts have done the heavy lifting so you don't have to. Using{" "}
            <strong>Vobb-Atlas</strong>, you'll have access to our plethora of universities to give
            your prospect more education options.
          </p>
          <a href="http://atlas.vobb.io" target="_blank" rel="noopener noreferrer">
            Get Started
          </a>
        </div>
        <div ref={zoomIn} className={styles.contentTop__right}>
          <Stanford className={styles.stanford} />
          <Liverpool className={styles.liverpool} />
          <Washington className={styles.washington} />
          <Wellington className={styles.wellington} />
          <Murdoch className={styles.murdoch} />
          {/* <Murdoch className={styles.murdoch2} /> */}
          <Coventry className={styles.coventry} />
          <Minnesota className={styles.minnesota} />
        </div>
      </div>
    </Section>
  );
};

export { ScaleGlobally };
