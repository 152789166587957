import { createContext, ReactNode, useContext, useState } from "react";
type FormType = "personal" | "company" | "features";

type CompletedFormsType =
  | []
  | [FormType]
  | [FormType, FormType]
  | [FormType, FormType, FormType]
  | any;

interface EarlyAccessContextType {
  activeForm: FormType;
  completedForms: CompletedFormsType;
  setActiveForm: (form: FormType) => void;
  handleFormChange: (form: FormType, completed: CompletedFormsType) => void;
}

const defaultValue: EarlyAccessContextType = {
  activeForm: "personal",
  setActiveForm: () => {},
  completedForms: [],
  handleFormChange: () => {}
};

const EarlyAccessContext = createContext<EarlyAccessContextType>(defaultValue);

interface EarlyAccessContextProviderProps {
  children: ReactNode;
}

export const useEarlyAccessContext = () => {
  return useContext(EarlyAccessContext);
};

export const EarlyAccessContextProvider = ({ children }: EarlyAccessContextProviderProps) => {
  const [activeForm, setActiveForm] = useState<FormType>("personal");
  const [completedForms, setCompletedForms] = useState<CompletedFormsType>([]);

  const handleFormChange = (form: FormType, completed: CompletedFormsType) => {
    setActiveForm(form);
    setCompletedForms(completed);
  };

  return (
    <EarlyAccessContext.Provider
      value={{ activeForm, setActiveForm, completedForms, handleFormChange }}>
      {children}
    </EarlyAccessContext.Provider>
  );
};
