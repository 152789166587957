import { Section, Title } from "components";
import styles from "./styles.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const CaseStudies = () => {
  const slideUp1 = useRef(null);
  const slideUp2 = useRef(null);

  useGSAP(
    () => {
      gsap.from(slideUp1.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp1.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp1 }
  );

  useGSAP(
    () => {
      gsap.from(slideUp2.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp2.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp2 }
  );

  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.contentbg}>
      <Title variant="small">Case Study</Title>
      <div className={styles.content}>
        <div ref={slideUp1} className={styles.content__left}>
          <h4 className={styles.content__bigHeader}>
            Mckenwin uses Vobb to Manage applications, teams and finances.
          </h4>
          <div className={styles.content__badges}>
            <span>Vobb Atlas</span>
            <span>Pipeline</span>
            <span>Finance</span>
            <span>Sendup</span>
            <span>Sales</span>
          </div>
        </div>
        <div ref={slideUp2} className={styles.content__right}>
          <Title variant="small" className={styles["content__right--title"]}>
            Mckenwin is an Educational Agency that specializes in successfully representing and
            placing students in Universities in the UK, USA, and Canada.
          </Title>
          <div className={styles.challenges}>
            <Title variant="small" className={styles["content__right--title2"]}>
              The Challenge
            </Title>
            <p>Students can afford to be selective, and we learned this the hard way.</p>
            <p>
              We had major issues with losing students due to the lag in researching courses and
              institution options through each institutions website, moving students and
              applications quickly through the pipeline, keeping a team-wide overview of each
              students status and ultimately collaborating for efficiency.
            </p>
          </div>
          <div className={styles.results}>
            <Title variant="small" className={styles["content__right--title2"]}>
              The Results
            </Title>
            <p>
              With Vobb we created a superior student onboarding experience and shortened the time
              spent on institution research by automatically segmenting and matching our prospects
              with the right available institutions since we chose to use the{" "}
              <a target="_blank" href="https://atlas.vobb.io">
                Atlas
              </a>{" "}
              service.
            </p>
            <p>
              The intelligent forms allowed us to automate collection of student data and the form
              automatically assigns student to the team members we set. It’s just made our whole
              organisation a lot more in sync.
            </p>
          </div>

          <div className={styles.stats}>
            <div>
              <Title variant="small" className={styles["content__right--title3"]}>
                275%
              </Title>
              <p>Increase in productivity across teams.</p>
            </div>
            <div>
              <Title variant="small" className={styles["content__right--title3"]}>
                700+
              </Title>
              <p>Enquiries received per month from prospective students.</p>
            </div>
            <div>
              <Title variant="small" className={styles["content__right--title3"]}>
                4hrs
              </Title>
              <p>Saved daily on mundane and repeatitive tasks.</p>
            </div>
            <div>
              <Title variant="small" className={styles["content__right--title3"]}>
                3x
              </Title>
              <p>More deals closed and students enrolled. </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export { CaseStudies };
