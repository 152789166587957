import { Title, Section } from "components";
import styles from "./styles.module.scss";
import { Analysis, AppPipeline, AssignStudents, OverviewAutomateEdu, UserEdu } from "assets";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const AutomateAgency = () => {
  const slideLeft = useRef(null);
  const zoomIn = useRef(null);

  useGSAP(
    () => {
      gsap.from(slideLeft.current, {
        duration: 1,
        opacity: 0,
        x: 90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideLeft.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideLeft }
  );

  useGSAP(
    () => {
      gsap.from(zoomIn.current, {
        duration: 0.8,
        // opacity: 0,
        // x: 90,
        scaleX: 0.6,
        scaleY: 0.6,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: zoomIn.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: zoomIn }
  );

  return (
    <Section innerRef={slideLeft} bgClassName={styles.bg} sectionClassName={styles.content}>
      <div className={styles.contentTop}>
        <Title variant="small">Automate your agency</Title>
        <h2 className={styles.contentTop__bigHeader}>From Prospects to happy students.</h2>
        <p className={styles.contentTop__paragraph}>
          Collect, manage and oversee education travel facilitation for your students and partners
          from one setting.
        </p>
      </div>
      <div className={styles.contentBtm}>
        <div className={styles.contentBtm__item}>
          <UserEdu className={styles.image} />
          <p className={styles.title}>Acquire students</p>
          <p>
            Create as many as 1,000,000 detailed students and prospects records without all the
            work. Log sales activities automatically, and easily keep your records up to date.
          </p>
        </div>

        <div className={styles.contentBtm__item}>
          <AssignStudents className={styles.image} />
          <p className={styles.title}>Assign students</p>
          <p>
            Pre-defined workflow rules let you assign students to sales representatives, advisors,
            counsellors, team members based on geography, interests, or pipeline stage.
          </p>
        </div>

        <div className={styles.contentBtm__item}>
          <AppPipeline className={styles.image} />
          <p className={styles.title}>Manage a sales pipeline</p>
          <p>
            Having a close eye on your deals is easier when you can see the deal stage and create
            distinct sales pipelines that can be customized with the different stages to suit your
            sales process.
          </p>
        </div>

        <div className={styles.contentBtm__item}>
          <Analysis className={styles.image} />
          <p className={styles.title}>Analysis and insights</p>
          <p>
            Access detailed reports on sales activity, productivity, and individual performance.
            Then use those insights to track team performance and spot opportunities for growth.
          </p>
        </div>
      </div>
      <img ref={zoomIn} src={OverviewAutomateEdu} alt="education" className={styles.contentLast} />
    </Section>
  );
};

export { AutomateAgency };
