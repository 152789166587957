import { ProgressBar, Section } from "components";
import styles from "./styles.module.scss";
import { Title } from "components/title";
import { Experience, Growth, Sell, Settings } from "assets";
import { ReactElement, useRef } from "react";
import { useCarousel } from "hooks";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

interface CarouselData {
  icon: ReactElement<SVGElement>;
  title: string;
  description: string;
}
const WhyVobb = () => {
  const dataIndex = ["1", "2", "3", "4"];
  const { currentIndex } = useCarousel(dataIndex);
  const carouselItems: CarouselData[] = [
    {
      icon: <Settings />,
      title: "Frictionless Operations",
      description:
        "Without the need to export customer data or fill forms; Vobb lets you access travel services by clicking a button."
    },
    {
      icon: <Growth />,
      title: "Steadfast Growth",
      description:
        "Bring the very best out of your customer-facing teams with robust automation and comprehensive analytics."
    },
    {
      icon: <Sell />,
      title: "Sell Like Never Before",
      description:
        "Leverage smart segmentation features to precisely retarget your leads and their peers based on interests."
    },
    {
      icon: <Experience />,
      title: "Unmatched Experience",
      description:
        "Amaze your leads and customers with personalised experiences using Vobbs customizable tools to close leads."
    }
  ];

  const slideRight = useRef(null);

  useGSAP(
    () => {
      gsap.from(slideRight.current, {
        duration: 1,
        // opacity: 0,
        x: -90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideRight.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse",
          // markers: true
        }
      });
    },
    { scope: slideRight }
  );

  return (
    <Section innerRef={slideRight} bgClassName={styles.bg} sectionClassName={styles.content}>
      <Title variant="small">Why Vobb</Title>
      <h2 className={styles.content__heading}>A one-click approach to selling travel services</h2>
      <p className={styles.content__paragraph}>
        Increase sales, customer retention and overall customer experience using Vobb's fully
        centralized tools and integrations designed especially for travel agents.
      </p>

      {/* desktop */}
      <div className={`${styles.articleList} ${styles.desktop}`}>
        {carouselItems.map((item, index) => (
          <article className={styles.reason} key={index}>
            {item.icon}
            <p className={styles.reason__ttl}>{item.title}</p>
            <p className={styles.reason__txt}>{item.description}</p>
          </article>
        ))}
      </div>

      {/* mobile */}
      <div className={`${styles.articleList} ${styles.mobile}`}>
        {carouselItems.map((item, index) => (
          <article
            className={styles.reason}
            key={index}
            style={{ transform: `translate(-${currentIndex * 100}%)` }}>
            {item.icon}
            <p className={styles.reason__ttl}>{item.title}</p>
            <p className={styles.reason__txt}>{item.description}</p>
          </article>
        ))}
      </div>

      <ProgressBar currentIndex={currentIndex} totalItems={dataIndex.length} />
    </Section>
  );
};

export { WhyVobb };
