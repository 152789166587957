import { Section } from "components";
import styles from "./styles.module.scss";
import { Title } from "components/title";
import { Arrow, EducationIcon, HealthIcon, TourIcon } from "assets";
import { Link } from "react-router-dom";
import { Routes } from "router";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const TravelAgents = () => {
  const fadeIn = useRef(null);

  useGSAP(
    () => {
      gsap.from(fadeIn.current, {
        duration: 1.5,
        opacity: 0,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: fadeIn.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: fadeIn }
  );

  return (
    <Section innerRef={fadeIn} bgClassName={styles.bg} sectionClassName={styles.content}>
      <Title variant="small">Designed for travel agents.</Title>
      <h2 className={styles.content__heading}>Your travel agency on wheels.</h2>
      <p className={styles.content__paragraph}>
        By automating tasks like retention, reporting, and billing; our operating system makes life
        easier for all sectors of the travel industry.
      </p>
      <div className={styles.articleList}>
        <article className={styles.article}>
          <EducationIcon />
          <p className={styles.article__ttl}>Education Consultants</p>
          <p className={styles.article__txt}>
            Manage your institutions, applications, commissions, and gain access to a network of
            university affiliations to empower your agents and consultants while attracting exciting
            prospects.
          </p>
          <div className={styles.article__actions}>
            <Link to={Routes.educationAgency} className={styles["article__actions--explore"]}>
              Explore solution
              <Arrow className={styles.arrowPurple} />
            </Link>
            <a
              href={"https://atlas.vobb.io/"}
              target="_blank"
              rel="noreferrer"
              className={styles["article__actions--atlas"]}>
              Visit Atlas
              <Arrow className={styles.arrowAtlas} />
            </a>
          </div>
        </article>
        <article className={styles.article}>
          <HealthIcon />
          <p className={styles.article__ttl}>Med-Travel Agents</p>
          <p className={styles.article__txt}>
            Acquire and nurture patients through intelligent forms and efficiently organize your
            patients journey from Clinic appointments to Visas and to flight arrangements all in one
            place.
          </p>
          <div className={styles.article__actions}>
            <Link to={Routes.medicalAgency} className={styles["article__actions--explore"]}>
              Explore solution
              <Arrow className={styles.arrowPurple} />
            </Link>
          </div>
        </article>
        <article className={styles.article}>
          <TourIcon />
          <p className={styles.article__ttl}>Migration & Tour Agents</p>
          <p className={styles.article__txt}>
            Build and manage your itineraries, discovery calls, bookings, reservations and clientele
            in a structured and centralized manner with our automated systems.
          </p>
          <div className={styles.article__actions}>
            <Link to={Routes.tourAgency} className={styles["article__actions--explore"]}>
              <span>Explore solution</span>
              <Arrow className={styles.arrowPurple} />
            </Link>
          </div>
        </article>
      </div>
    </Section>
  );
};

export { TravelAgents };
