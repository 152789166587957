import { Title, Section } from "components";
import styles from "./styles.module.scss";
import { AboutHero1, AboutHero2 } from "assets";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const HeroSection = () => {
  const slideRight = useRef(null);
  const slideLeft = useRef(null);
  const slideLeft2 = useRef(null);

  useGSAP(
    () => {
      gsap.from(slideRight.current, {
        duration: 1,
        opacity: 0,
        x: -90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideRight.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideRight }
  );

  useGSAP(
    () => {
      gsap.from(slideLeft.current, {
        duration: 1,
        opacity: 0,
        x: 90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideLeft.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideLeft }
  );

  useGSAP(
    () => {
      gsap.from(slideLeft2.current, {
        duration: 1,
        opacity: 0,
        x: 90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideLeft2.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideLeft2 }
  );

  return (
    <>
      <Section bgClassName={styles.heroBg} sectionClassName={styles.hero}>
        <div className={styles.hero__top}>
          <div ref={slideRight} className={styles["hero__top--left"]}>
            <Title variant="small">We are Vobb</Title>
            <Title variant="large" className={styles.hero__bigHeader}>
              We set out to build <br /> a better way to{" "}
              <strong>manage your Travel business</strong>
            </Title>
          </div>

          <p ref={slideLeft} className={styles["hero__top--right"]}>
            Together - the team and partners of Vobb - we are reinventing how we facilitate travels.
          </p>
        </div>
      </Section>
      <div ref={slideLeft2} className={styles.imgSec}>
        <img src={AboutHero1} alt="" />
        <img src={AboutHero2} alt="" />
        {/* <HeroAbout className={styles["hero__btm--pic1"]} />
          <HeroAbout2 className={styles["hero__btm--pic2"]} /> */}
      </div>
    </>
  );
};

export { HeroSection };
