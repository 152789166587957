import { EducationConsultantUI } from "modules";
import React from "react";

const EducationConsultant = () => {
  return (
    <>
      <EducationConsultantUI />
    </>
  );
};

export { EducationConsultant };
