import { Title, Section } from "components";
import styles from "./styles.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import { Globe } from "./globe";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const GlobalScale = () => {
  const slideRight = useRef(null);
  const slideLeft = useRef(null);

  useGSAP(
    () => {
      gsap.from(slideRight.current, {
        duration: 1,
        // opacity: 0,
        x: -90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideRight.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideRight }
  );

  useGSAP(
    () => {
      gsap.from(slideLeft.current, {
        duration: 1,
        opacity: 0,
        x: -90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideLeft.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideLeft }
  );

  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.content}>
      <div ref={slideRight} className={styles.content__left}>
        <Title variant="small" className={styles.ttl}>
          Global scale
        </Title>
        <h2 className={styles.content__desc}>
          Connect your travel agency to any service anywhere.
        </h2>

        <p className={styles.txt}>
          For ambitious travel businesses around the world, Vobb makes accessing and selling travel
          services as borderless and accessible as the rest of the internet.
        </p>
        <p className={styles.txt}>
          With dozens of partners around the world, you’re always one click away from accessing
          services to help you efficiently facilitate travels .
        </p>
      </div>
      {/* <div  > */}
      <Globe ref={slideLeft} />
      {/* </div> */}
    </Section>
  );
};

export { GlobalScale };
