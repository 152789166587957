import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import {
  AboutIcon,
  AtlasIcon,
  BarsBlack,
  BlogIcon,
  BooksIcon,
  EducationIcon,
  HealthIcon,
  LogoColored,
  LogoWhite,
  OfferingsIcon,
  PipelineIcon,
  SendupIcon,
  TourIcon,
  Xmark
} from "assets";
import { Routes } from "router";
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { DropdownItem, MenuPortal } from "components";
import { useClickOutside } from "hooks";
import { useNavContext } from "context";

export interface MenuPortalData {
  icon: ReactElement<SVGElement>;
  title: string;
  paragraph?: string;
  isActive?: boolean;
  onClick: () => void;
  index?: number;
  url?: string;
}

type tabs = "home" | "about" | "blog" | "solutions";

export interface NavbarProps {
  active: tabs;
}

const Navbar: React.FC<NavbarProps> = ({ active }) => {
  const { scrollToElement } = useNavContext();
  const [addBg, setAddBg] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMobileNav, setActiveMobileNav] = useState("products");
  const navigate = useNavigate();
  const [reset, setReset] = useState(false);

  const handleScroll = () => {
    setAddBg(window.scrollY > 80);
  };
  window.addEventListener("scroll", handleScroll);

  const handleProductDisplay = async (prod: number) => {
    setReset((prev) => !prev);
    if (window.location.pathname !== "/") {
      await navigate("/");
      scrollToElement();
      setIsMenuOpen(false);
    } else {
      scrollToElement();
      setIsMenuOpen(false);
    }
  };

  const handleToggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };
  const handleActiveMobileNav = (value: string) => {
    setActiveMobileNav(value);
  };

  //Menu - Products
  const productsHeaders = ["PRODUCTS"];
  const productsItems: MenuPortalData[] = [
    {
      icon: <PipelineIcon />,
      title: "Pipeline",
      paragraph: "For managing clients & customers",
      index: 1,
      onClick: () => handleProductDisplay(1)
    },
    {
      icon: <OfferingsIcon />,
      title: "Sales",
      paragraph: "For creating and managing offerings and services.",
      index: 2,
      onClick: () => handleProductDisplay(2)
    },
    {
      icon: <AtlasIcon />,
      title: "Atlas",
      paragraph: "Application portal for education travel agents.",
      index: 3,
      onClick: () => handleProductDisplay(3)
    },
    {
      icon: <BooksIcon />,
      title: "Books",
      paragraph: "For managing mark-ups, commissions and revenue.",
      index: 4,
      onClick: () => handleProductDisplay(4)
    },

    {
      icon: <SendupIcon />,
      title: "Sendup",
      paragraph: "Email marketing tool for travel agents.",
      index: 5,
      onClick: () => handleProductDisplay(5)
    }
  ];

  //Menu - Solutions
  const solutionsHeaders = ["SOLUTIONS"];
  const solutionsItems: MenuPortalData[] = [
    {
      icon: <EducationIcon />,
      title: "Education Consultants",
      paragraph: "Acquire and nurture prospective students.",
      url: "/solutions/education-consultant",
      onClick: () => setReset((prev) => !prev)
    },
    {
      icon: <TourIcon />,
      title: "Tour Agency",
      paragraph: "Manage teams and cutomers,sell experiences.",
      url: "/solutions/tour-and-migration",
      onClick: () => setReset((prev) => !prev)
    },
    {
      icon: <HealthIcon />,
      title: "Health Tourism",
      paragraph: "Efficiently facilitate patients medical travels.",
      url: "/solutions/medical-travel",
      onClick: () => setReset((prev) => !prev)
    }
  ];

  const resourcesItems: MenuPortalData[] = [
    {
      icon: <AboutIcon />,
      title: "About us",
      url: "/about",
      onClick: () => setReset((prev) => !prev)
    },
    // {
    //   icon: <BlogIcon />,
    //   title: "Blog",
    //   url: "/blog",
    //   onClick: () => setReset((prev) => !prev)
    // }
  ];
  const navItems: NavItemData[] = [
    {
      title: "Home",
      type: "link",
      url: Routes.home,
      isActive: active === "home"
    },
    {
      title: "Products",
      type: "menu",
      menu: (
        <>
          <MenuPortal products={productsItems} headers={productsHeaders} />
        </>
      )
    },
    {
      title: "Solutions",
      type: "menu",
      menu: (
        <>
          <MenuPortal products={solutionsItems} headers={solutionsHeaders} />
        </>
      ),
      isActive: active === "solutions"
    },
    {
      title: "About us",
      type: "link",
      url: Routes.about,
      isActive: active === "about"
    },
    // {
    //   title: "Blog",
    //   type: "link",
    //   url: Routes.blog,
    //   isActive: active === "blog"
    // }
  ];

  const mobileNavHeaders = ["products", "solutions", "resources"];

  const whiteNav = active !== "home" || addBg || isMenuOpen;

  return (
    <>
      <section className={`${styles.navWrap} ${whiteNav ? styles["navWrap--white"] : ""}`}>
        <header className={`container ${styles.header} ${whiteNav ? styles["header--white"] : ""}`}>
          <Link to={Routes.home}>{!whiteNav ? <LogoWhite /> : <LogoColored />}</Link>
          <nav className={styles.nav}>
            {navItems.map((item) => (
              <NavItem key={item.title} {...item} reset={reset} />
            ))}
          </nav>
          <Link to={Routes.earlyAccess} className={styles.header__btn}>
            Early access
          </Link>

          <nav className={styles.header__mobile}>
            {isMenuOpen ? (
              <Xmark onClick={handleToggleMenu} />
            ) : (
              <BarsBlack
                style={whiteNav ? { fill: "#161616" } : { fill: "#fff" }}
                onClick={handleToggleMenu}
              />
            )}

            {isMenuOpen && (
              <main className={styles["header__mobile--body"]}>
                <div className={styles.content}>
                  <div className={styles.content__headers}>
                    {mobileNavHeaders.map((item) => (
                      <p
                        className={
                          activeMobileNav === item ? styles["content__headers--active"] : ""
                        }
                        key={item}
                        onClick={() => handleActiveMobileNav(item)}>
                        {item}
                      </p>
                    ))}
                  </div>

                  {activeMobileNav === "products" && (
                    <div className={styles.content__item} onClick={() => setIsMenuOpen(false)}>
                      {productsItems?.map((item) => (
                        <DropdownItem key={item.title} {...item} />
                      ))}
                    </div>
                  )}
                  {activeMobileNav === "solutions" && (
                    <div onClick={() => setIsMenuOpen(false)}>
                      {solutionsItems?.map((item) => (
                        <DropdownItem key={item.title} {...item} />
                      ))}
                    </div>
                  )}
                  {activeMobileNav === "resources" && (
                    <div onClick={() => setIsMenuOpen(false)}>
                      {resourcesItems?.map((prod) => (
                        <DropdownItem key={prod.title} {...prod} />
                      ))}
                    </div>
                  )}
                  <div className={styles.content__bottom}>
                    <Link to={Routes.earlyAccess} className={styles["content__bottom--btn"]}>
                      Early access
                    </Link>
                  </div>
                </div>
              </main>
            )}
          </nav>
        </header>
      </section>
    </>
  );
};

interface NavItemData {
  type: "menu" | "link";
  title: string;
  url?: string;
  menu?: ReactNode;
  isActive?: boolean;
}

interface NavItemProps extends NavItemData {
  reset: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ title, type, isActive, url, menu, reset }) => {
  const [show, setShow] = useState(false);
  const menuRef = useRef(null);
  useClickOutside(menuRef, () => setShow(false));

  useEffect(() => {
    setShow(false);
  }, [reset]);

  return (
    <>
      {type === "menu" && menu ? (
        <div ref={menuRef} className={styles.navMenu}>
          <button
            onClick={() => setShow((prev) => !prev)}
            className={`${styles.navItem} ${isActive ? styles["navItem--active"] : ""}`}>
            {title}
          </button>
          {show ? menu : ""}
        </div>
      ) : url ? (
        <Link className={`${styles.navItem} ${isActive ? styles["navItem--active"] : ""}`} to={url}>
          {title}
        </Link>
      ) : (
        ""
      )}
    </>
  );
};

export { Navbar };
