import { PathRouteProps } from "react-router-dom";
import { Routes } from "./routes";
import {
  About,
  Blog,
  BlogDetails,
  Completed,
  EarlyAccess,
  EducationConsultant,
  Home,
  MedicalTravel,
  TourAndMigration
} from "pages";
import { EarlyAccessLayout, LandingLayout } from "layout";

// Route Builder Item Props
export interface RouteBuilderItem extends PathRouteProps {
  Layout?: React.FC<any>; // If you wish to add a layout to the page
  Element: React.FC;
  props?: any;
}

/**
 * ROUTE BUILDER
 *
 * ===============================================
 *
 * This is a list of all our application routes.
 *
 * A single item on this list contains the necessary Route props needed by React Router to do it's job.
 *
 * If you wish to add a new route to the application,
 * just fulfill all the necessary props needed by the RouteBuilder item. Ciao!
 *
 */
export const RouteBuilder: RouteBuilderItem[] = [
  {
    path: Routes.home,
    Element: Home,
    Layout: LandingLayout,
    props: {
      active: "home"
    }
  },
  {
    path: Routes.about,
    Element: About,
    Layout: LandingLayout,
    props: {
      active: "about"
    }
  },
  {
    path: Routes.educationAgency,
    Element: EducationConsultant,
    Layout: LandingLayout,
    props: {
      active: "solutions"
    }
  },
  {
    path: Routes.tourAgency,
    Element: TourAndMigration,
    Layout: LandingLayout,
    props: {
      active: "solutions"
    }
  },
  {
    path: Routes.medicalAgency,
    Element: MedicalTravel,
    Layout: LandingLayout,
    props: {
      active: "solutions"
    }
  },
  {
    path: Routes.earlyAccess,
    Element: EarlyAccess,
    Layout: EarlyAccessLayout
  },
  {
    path: Routes.completed,
    Element: Completed
  },
  {
    path: Routes.blog,
    Element: Blog,
    Layout: LandingLayout,
    props: { hideGetStarted: true, active: "blog" }
  },
  {
    path: Routes.blogDetails,
    Element: BlogDetails,
    Layout: LandingLayout,
    props: { hideGetStarted: true, active: "blog" }
  },
  {
    path: "*",
    Element: Home,
    Layout: LandingLayout,
    props: {
      active: "home"
    }
  }
];
