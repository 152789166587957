import { HeroSection } from "./heroSection";
import { BlogCategoriesProps, Categories } from "./categories";

interface Props extends BlogCategoriesProps {}

const BlogUI: React.FC<Props> = (props) => {
  const { overviewData } = props;
  return (
    <>
      <HeroSection overviewData={overviewData} />
      <Categories {...props} />
    </>
  );
};

export { BlogUI };
