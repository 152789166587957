import React from "react";
// import styles from './styles.module.scss';
import { HowWeWork } from "./howWeWork";
import { HeroSection } from "./heroSection";
import { SuccessStories } from "./successStories";
import { Agents } from "./agents";
import { DistributedTeam } from "./distributedTeam";

const AboutUI = () => {
  return (
    <>
      <HeroSection />
      <Agents />
      {/* <TrustedBrands /> */}
      <DistributedTeam />
      {/* <HowWeWork /> */}
      {/* <SuccessStories /> */}
    </>
  );
};

export { AboutUI };
