import { Section } from "components";
import styles from "./styles.module.scss";
import { SegmentationTour } from "assets";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const Segmentation = () => {
  const slideRight = useRef(null);
  const fadeIn = useRef(null);

  useGSAP(
    () => {
      gsap.from(fadeIn.current, {
        duration: 1,
        opacity: 0,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: fadeIn.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: fadeIn }
  );

  useGSAP(
    () => {
      gsap.from(slideRight.current, {
        duration: 1,
        opacity: 0,
        x: -120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideRight.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideRight }
  );
  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.content}>
      <div ref={fadeIn} className={styles.contentTop}>
        <h3 className={styles.contentTop__bigHeader}>Automate the segmentation process.</h3>
        <p className={styles.contentTop__paragraph}>
          Create segments, define rules for the created segments and watch <span>Pipeline</span> automatically sort
          your customers into segments right from acquisition and every stage of your sales
          pipeline.
        </p>
      </div>
      <SegmentationTour ref={slideRight} />
    </Section>
  );
};

export { Segmentation };
