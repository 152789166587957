import { useEffect, useState } from "react";

export const useCarousel = (data: string[], time = 5000) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const handleDirectIndex = (index: number) => {
    if (index >= 0 && index < data.length) {
      setCurrentIndex(index);
    }
  };

  const carouselBtnScroll = (dir: "left" | "right"): void => {
    if (dir === "left") {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? data.length - 1 : prevIndex - 1));
    } else if (dir === "right") {
      setCurrentIndex((prevIndex) => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
    }
  };

  const carouselInfiniteScroll = (): void => {
    setCurrentIndex((prevIndex) => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      carouselInfiniteScroll();
    }, time);

    return () => clearInterval(interval);
  }, []);

  return { currentIndex, carouselBtnScroll, handleDirectIndex };
};
